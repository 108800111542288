<template>
  <NbSpinner v-if="state.loading" align="center" size="2.1rem" class="mt-4" />
  <TotalOrdersChart v-else :values="chartData" style="height: 500px" />
</template>

<script>
import moment from "moment";
import TotalOrdersChart from "./TotalOrdersChart.vue";
import NbSpinner from "../../../components/loadings/NbSpinner.vue";

export default {
  name: "TotalOrdersLast30Days",
  props: {
    overview: {
      type: Array,
      default: () => [],
    },
  },
  inject: ["state"],
  components: { TotalOrdersChart, NbSpinner },
  computed: {
    chartData() {
      const startDate = moment().subtract(29, "days");
      const currentDate = moment();
      const diffInDays = currentDate.diff(startDate, "days");

      const result = [];

      let nextDate = startDate;
      for (let i = 0; i <= diffInDays; i++) {
        const parseDate = nextDate.format("YYYY-MM-DD");
        const matchData = this.overview.find((item) => item.day === parseDate);
        result.push({
          date: matchData?.day || parseDate,
          value: matchData?.orders || 0,
        });

        nextDate = nextDate.add(1, "day");
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped></style>
