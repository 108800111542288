<template>
  <NbCardBox class="d-flex flex-column">
    <h4 class="heading-4 mb-3">Status</h4>

    <div class="alerts flex-grow-1">
      <NbSpinner v-if="state.loading" size="2.1rem" align="center" />
      <p v-if="!state.loading && !hasStatusCount" class="body-4">
        Nenhuma informação a ser exibida.
      </p>
      <template v-if="hasStatusCount">
        <Alert
          v-if="totalNewOrders"
          type="warning"
          icon="alert-circle"
          :message="
            $t('homePage.totalAwaitingYourAction', { total: totalNewOrders })
          "
        />

        <Alert
          v-if="totalPickupOrders"
          type="warning"
          icon="alert-circle"
          :message="
            $t('homePage.totalAwaitingPickup', { total: totalPickupOrders })
          "
          class="my-2"
        />

        <Alert
          v-if="totalShippedOrders"
          type="success"
          icon="far fa-check-circle"
          :message="
            $t('homePage.totalShippedOrders', { total: totalShippedOrders })
          "
        />
      </template>
    </div>

    <div class="text-right">
      <router-link to="/orders" class="btn-link">
        Ver todos os pedidos >
      </router-link>
    </div>
  </NbCardBox>
</template>

<script>
import NbCardBox from "@/components/cards/NbCardBox.vue";
import Alert from "@/components/alerts/Alert.vue";
import { ORDER_STATUS } from "../../../constants";
import NbSpinner from "../../../components/loadings/NbSpinner.vue";
export default {
  name: "OrdersStatusCard",
  props: {
    statusInfo: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NbCardBox,
    Alert,
    NbSpinner,
  },
  inject: ["state"],
  computed: {
    hasStatusCount() {
      return !!this.statusInfo.find((item) => item.volumes > 0);
    },
    totalNewOrders() {
      return (
        this.statusInfo?.find((item) => item.tracking_code === ORDER_STATUS.NEW)
          ?.volumes || 0
      );
    },
    totalShippedOrders() {
      return (
        this.statusInfo?.find(
          (item) => item.tracking_code === ORDER_STATUS.SHIPPED
        )?.volumes || 0
      );
    },
    totalPickupOrders() {
      return (
        this.statusInfo?.find(
          (item) => item.tracking_code === ORDER_STATUS.PICKUP
        )?.volumes || 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-link {
  background: transparent;
  outline: none;
  border: none;
  color: var(--gray-60);
  text-decoration: underline;
}
</style>
