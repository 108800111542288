<template>
  <BaseTemplate>
    <NbPageTitle :title="welcomeUser" :subTitle="$t('homePage.subtitle')" />

    <div class="items mx-3 mb-4">
      <div class="cards">
        <QuickLinks class="links" />
        <OrdersStatusCard :status-info="overview.status_info" />
      </div>

      <NbCardBox>
        <CurrentMonthOverview
          :freight-cost="overview.freight_cost"
          :freight-value="overview.freight_value"
          :rejected-orders="overview.rejected_orders"
          :average-order-cost="overview.average_order_cost"
        />
        <TotalOrdersLast30Days :overview="overview.cost_volumes_info" />
      </NbCardBox>
    </div>
  </BaseTemplate>
</template>

<script>
import NbPageTitle from "../../components/pagescomponents/NbPageTitle.vue";
import BaseTemplate from "../BaseTemplate";
import CurrentMonthOverview from "./components/CurrentMonthOverview.vue";
import OrdersStatusCard from "./components/OrdersStatusCard.vue";
import QuickLinks from "./components/QuickLinks.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import TotalOrdersLast30Days from "./components/TotalOrdersLast30Days.vue";
import SellerService from "../../services/SellerService.js";
import moment from "moment";

const sellerService = new SellerService();

export default {
  name: "Home",
  components: {
    BaseTemplate,
    NbPageTitle,
    QuickLinks,
    CurrentMonthOverview,
    OrdersStatusCard,
    TotalOrdersLast30Days,
    NbCardBox,
  },
  data() {
    return {
      loading: true,
      user: this.$helpers.getUser(),
      sellerId: null,
      filters: {
        start_date: null,
        end_date: null,
      },
      overview: {
        freight_value: 0,
        freight_cost: 0,
        average_order_cost: 0,
        rejected_orders: 0,
        cost_volumes_info: [],
        status_info: [],
      },
    };
  },
  provide() {
    const state = {};
    Object.defineProperty(state, "loading", {
      enumerable: true,
      get: () => this.loading,
    });
    return { state };
  },
  computed: {
    welcomeUser() {
      return `${this.$t("hello")}, ${this.user.name}`;
    },
  },
  methods: {
    async getCurrentSeller() {
      const { data } = await sellerService.getCurrentSeller();
      this.sellerId = data.data.id;
      this.getAnalyticDatas();
    },
    async getAnalyticDatas() {
      try {
        const {
          data: { data },
        } = await sellerService.getAnalyticDatas(this.filters, this.sellerId);
        this.overview = {
          freight_value: data.freight_value,
          freight_cost: data.freight_cost,
          average_order_cost: data.average_order_cost,
          rejected_orders: data.rejected_orders,
          cost_volumes_info: data.cost_volumes_info,
          status_info: data.status_info,
        };
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.filters = {
      start_date: moment().subtract(29, "days").format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
    };
    this.getAnalyticDatas();
  },
};
</script>

<style lang="scss" scoped>
.items > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}
.cards {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    .links {
      grid-column: span 2 / span 2;
    }
  }
}
</style>
