<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4 class="heading-4 m-0">{{ $t("homePage.createdOrders") }}</h4>
      <router-link
        to="/dashboard"
        class="text-dark text-decoration-none body-4 d-flex align-items-center"
      >
        <span>{{ $t("homePage.last30Days") }}</span>
        <NbIcon
          class="ml-3"
          icon="chevron-right"
          :attributes="{
            'stroke-width': 3,
            width: '1.3rem',
            height: '1.3rem',
          }"
        />
      </router-link>
    </div>

    <div class="boxes">
      <div class="box-info">
        <NbSpinner v-show="state.loading" size="2.1rem" class="mb-2" />
        <h1 v-show="!state.loading">{{ freightValue | formatCurrency }}</h1>
        <p class="body-4">{{ $t("homePage.freightValue") }}</p>
      </div>
      <div class="box-info">
        <NbSpinner v-show="state.loading" size="2.1rem" class="mb-2" />
        <h1 v-show="!state.loading">{{ freightCost | formatCurrency }}</h1>
        <p class="body-4">{{ $t("homePage.freightCost") }}</p>
      </div>
      <div class="box-info">
        <NbSpinner v-show="state.loading" size="2.1rem" class="mb-2" />
        <h1 v-show="!state.loading">
          {{ averageOrderCost | formatCurrency }}
        </h1>
        <p class="body-4">{{ $t("homePage.averageOrderCost") }}</p>
      </div>
      <div class="box-info">
        <NbSpinner v-show="state.loading" size="2.1rem" class="mb-2" />
        <h1 v-show="!state.loading">
          {{ state.loading ? "---" : rejectedOrders }}
        </h1>
        <p class="body-4">{{ $t("homePage.rejectedOrders") }}</p>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import NbSpinner from "../../../components/loadings/NbSpinner.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

export default {
  name: "OrdersCreatedOverview",
  props: {
    freightValue: {
      type: Number,
      default: 0,
    },
    freightCost: {
      type: Number,
      default: 0,
    },
    averageOrderCost: {
      type: Number,
      default: 0,
    },
    rejectedOrders: {
      type: Number,
      default: 0,
    },
  },
  inject: ["state"],
  filters: {
    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        style: "currency",
      }).format(value);
    },
  },
  components: {
    NbSpinner,
    NbIcon,
  },
};
</script>

<style lang="scss" scoped>
.boxes {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(4, 1fr);
  }

  .box-info {
    background-color: var(--background);
    padding: 1.87rem;
    border-radius: 4px;
    h1 {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}
</style>
