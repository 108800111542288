<template>
  <div class="links">
    <div v-for="link in links" :key="link.title" class="link">
      <div class="icon">
        <NbIcon
          icon="plus"
          :attributes="{
            'stroke-width': 3,
            width: '1.1rem',
            height: '1.1rem',
          }"
        />
      </div>
      <div>
        <h4 class="heading-4">{{ link.title }}</h4>
        <p class="body-3">{{ link.description }}</p>
        <NbButton variant="secondary" @click="redirectTo(link.path)">{{
          link.buttonText
        }}</NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
export default {
  name: "QuickLinks",
  components: {
    NbButton,
    NbIcon,
  },
  computed: {
    links() {
      return [
        {
          title: this.$t("orderCreate"),
          description: this.$t("homePage.addOrdersManually"),
          buttonText: this.$t("homePage.newOrder"),
          path: "/orders/ordercreate",
        },
        {
          title: this.$t("orderImport"),
          description: this.$t("homePage.uploadMultipleOrders"),
          buttonText: this.$t("homePage.addOrders"),
          path: "/orders/import",
        },
        {
          title: this.$t("homePage.integrateEcommerce"),
          description: this.$t("homePage.addYourFavoriteStores"),
          buttonText: this.$t("homePage.connectEcommerce"),
          path: "/integrations/channels",
        },
        {
          title: this.$t("homePage.quotation"),
          description: this.$t("homePage.simulateYourDelivery"),
          buttonText: this.$t("homePage.newQuotation"),
          path: "/quotes/new",
        },
      ];
    },
  },
  methods: {
    redirectTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.links {
  display: grid;
  gap: 1rem;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .link {
    display: flex;
    gap: 1.5rem;
    align-items: flex-start;
    background-color: var(--white);
    border-left: 4px solid var(--primary);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 4px 4px 8px var(--gray-05);
    padding: 1.5rem 1.125rem;
    .icon {
      height: 2.5rem;
      width: 2.5rem;
      background-color: var(--gray-05);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary);
    }
  }
}
</style>
